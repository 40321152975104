import React, { useState, useEffect } from "react";

const About = ({ horizontalabout }) => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        const savedTestimonials = localStorage.getItem('testimonials');
        if (savedTestimonials) {
            setTestimonials(JSON.parse(savedTestimonials));
        }
    }, []);

    return (
        <div className={`app-about ${horizontalabout}`} id="hakkimizda">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center mb--40">
                            <h2>Pet Kuaför <span className="theme-color">Nedir?</span></h2>
                            <p>Pet kuaför, evcil hayvanların hijyen, sağlık ve estetik ihtiyaçlarını karşılamak için özel olarak tasarlanmış bakım hizmetlerini sunan bir meslektir.</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-5 offset-lg-1 mt--40">
                        <div className="about-thumbnail mr--35">
                            <img className="image-1" src={require('../assets/images/app/mobile.png')} alt="App Landing"/>
                            <img className="image-2" src={require('../assets/images/app/mobile-2.png')} alt="App Landing"/>
                            <img className="image-3" src={require('../assets/images/app/mobile-3.png')} alt="App Landing"/>
                        </div>
                    </div>
                    <div className="col-lg-6 mt--40">
                        <div className="about-content">
                            <h2 className="title">Pet Kuaför <span className="theme-color">hizmetimizde neler var?</span></h2>
                            <p>Pet kuaförler, köpekler, kediler ve diğer evcil hayvanlar için profesyonel tıraş ve bakım işlemleri yapar. Bu hizmet, evcil hayvanların yalnızca dış görünüşlerini güzelleştirmekle kalmaz, aynı zamanda genel sağlıklarına katkıda bulunur.</p>
                            <p>Pet kuaförlerin sunduğu hizmetler, evcil hayvanların ihtiyaçlarına göre geniş bir yelpazeyi kapsar. İşte pet kuaförlerin sağladığı temel hizmetler ve bu hizmetlerin sağladığı faydalar: </p>
                            <h3>Hizmetlerimiz</h3>
                            <li>Tüy tarama ve bakım</li>
                            <li>Tıraş ve stilize kesimler</li>
                            <li>Banyo ve temizlik</li>
                            <li>Tırnak kesimi ve bakımı</li>
                            <li>Kulak ve göz temizliği</li>
                            <li>Patiler için özel bakım</li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;