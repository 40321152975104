import React, { Component } from "react";

class HeroOlive extends Component {
    render(){
        return(
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id="home" style={{background:'#2d3e50'}}>
                <div className="container h-100">
                    <div className="row">
                        <div className="col-lg-7 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    <h1>Pet <br /> <span className="theme-color">kuaför</span> istanbul</h1>
                                    <p>Profesyonel ekibimizle tıraş, banyo, tırnak kesimi, göz ve kulak temizliği gibi birçok hizmeti sevgi ve uzmanlıkla gerçekleştiriyoruz. Anestezisiz tıraş gibi özel çözümlerimizle dostlarınızın rahatlığını ön planda tutuyoruz.</p>
                                    <div className="banner-buttons">
                                        <a href="/" className="button-default button-transparent">İLETİŞİM</a>
                                        <a href="https://wa.me/+905324107738" 
                                           target="_blank" 
                                           rel="noopener noreferrer" 
                                           className="button-default button-olive">
                                            WHATSAPP
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeroOlive;








