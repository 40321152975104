import React, { useState, useEffect, Fragment } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const BlogDetails = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState({
        name: '',
        email: '',
        message: ''
    });

    // HTML'i temizle ve metni kısalt
    const truncateText = (text, maxLength = 160) => {
        if (!text) return '';
        const strippedText = text.replace(/<[^>]+>/g, '');
        if (strippedText.length <= maxLength) return strippedText;
        return strippedText.substr(0, maxLength) + '...';
    };

    // Meta description için HTML taglerini temizle
    const stripHtml = (html) => {
        if (!html) return '';
        return html.replace(/<[^>]+>/g, '');
    };

    // Schema.org yapısını oluştur
    const generateSchema = (post) => {
        return {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": post.title,
            "image": post.image,
            "author": {
                "@type": "Person",
                "name": post.postBy
            },
            "publisher": {
                "@type": "Organization",
                "name": "Pet Kuaför",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://your-domain.com/logo.png"
                }
            },
            "datePublished": post.date,
            "dateModified": post.date,
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": `https://your-domain.com/blog/${slug}`
            },
            "description": truncateText(post.desc),
            "articleBody": stripHtml(post.desc)
        };
    };

    // Navbar için efekt
    useEffect(() => {
        window.scrollTo(0, 0);
        const header = document.querySelector('.app-header');
        if (header) {
            // Transparent class'ı kaldır ve arka plan rengini ayarla
            header.classList.remove('header--transparent');
            header.style.backgroundColor = '#2d3e50';
        }

        return () => {
            const header = document.querySelector('.app-header');
            if (header) {
                header.style.backgroundColor = '';
            }
        };
    }, []);

    // URL'yi SEF yapmak için fonksiyon
    const createSEFUrl = (title) => {
        return title
            .toLowerCase()
            .replace(/[ğ]/g, 'g')
            .replace(/[ü]/g, 'u')
            .replace(/[ş]/g, 's')
            .replace(/[ı]/g, 'i')
            .replace(/[ö]/g, 'o')
            .replace(/[ç]/g, 'c')
            .replace(/[^a-z0-9]/g, '-')
            .replace(/-+/g, '-')
            .replace(/^-|-$/g, '');
    };

    useEffect(() => {
        const savedPosts = localStorage.getItem('blogPosts');
        if (savedPosts) {
            const posts = JSON.parse(savedPosts);
            // Slug'a göre makaleyi bul
            const currentPost = posts.find(p => createSEFUrl(p.title) === slug);
            if (currentPost) {
                setPost(currentPost);
                document.title = `${currentPost.title} | Pet Kuaför`;
            }
        }
    }, [slug]);

    // Yorumları yükle
    useEffect(() => {
        const savedComments = localStorage.getItem(`comments_${slug}`);
        if (savedComments) {
            setComments(JSON.parse(savedComments));
        }
    }, [slug]);

    const handleCommentSubmit = (e) => {
        e.preventDefault();
        const comment = {
            id: Date.now(),
            ...newComment,
            date: new Date().toLocaleDateString('tr-TR'),
            postSlug: slug
        };

        const updatedComments = [...comments, comment];
        setComments(updatedComments);
        localStorage.setItem(`comments_${slug}`, JSON.stringify(updatedComments));

        // Formu temizle
        setNewComment({
            name: '',
            email: '',
            message: ''
        });
    };

    if (!post) {
        return (
            <Fragment>
                <Navbar />
                <div className="blog-details-area pt--120 pt_md--80 pt_sm--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>Makale bulunamadı</h2>
                                <Link to="/blog" className="button-link">Blog Sayfasına Dön</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer horizontal="vertical-footer"/>
            </Fragment>
        );
    }

    return (
        <Fragment>
            {post && (
                <Helmet>
                    <title>{post.title} | Pet Kuaför</title>
                    <meta name="description" content={stripHtml(post.desc).substring(0, 160)} />
                    
                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content={post.title} />
                    <meta property="og:description" content={stripHtml(post.desc).substring(0, 160)} />
                    {post.image && <meta property="og:image" content={post.image} />}
                    <meta property="og:url" content={`https://your-domain.com/blog/${slug}`} />
                    
                    {/* Twitter */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={post.title} />
                    <meta name="twitter:description" content={stripHtml(post.desc).substring(0, 160)} />
                    {post.image && <meta name="twitter:image" content={post.image} />}

                    {/* Canonical URL */}
                    <link rel="canonical" href={`https://your-domain.com/blog/${slug}`} />

                    {/* Schema.org yapısı */}
                    <script type="application/ld+json">
                        {JSON.stringify(generateSchema(post))}
                    </script>
                </Helmet>
            )}

            <Navbar />
            <div className="blog-details-area pt--120 pt_md--80 pt_sm--80" style={{backgroundColor: '#f8f9fc'}}>
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-details-wrapper">
                                {post.image && (
                                    <div className="blog-image">
                                        <img 
                                            src={post.image} 
                                            alt={post.title}
                                            className="w-100"
                                        />
                                        </div>
                                )}
                                <div className="blog-content">
                                    <h2 className="title">{post.title}</h2>
                                    <div className="meta-info">
                                        <ul>
                                            <li>{post.date}</li>
                                            <li>Yazar: {post.postBy}</li>
                                        </ul>
                                                </div>
                                    <div 
                                        className="content"
                                        dangerouslySetInnerHTML={{ __html: post.desc }}
                                    />
                                                        </div>
                                                    </div>

                            {/* Yorum Bölümü */}
                            <div className="blog-details-wrapper">
                                <div className="comments-section">
                                    <h3>Yorumlar ({comments.length})</h3>
                                    
                                    {/* Yorum Listesi */}
                                    <div className="comments-list">
                                        {comments.length === 0 ? (
                                            <div className="no-comments">
                                                <p>Henüz yorum yapılmamış. İlk yorumu siz yapın!</p>
                                            </div>
                                        ) : (
                                            comments.map(comment => (
                                                <div key={comment.id} className="comment-item">
                                                    <div className="comment-header">
                                                        <h4>{comment.name}</h4>
                                                        <span className="comment-date">{comment.date}</span>
                                                    </div>
                                                    <p className="comment-text">{comment.message}</p>
                                                </div>
                                            ))
                                        )}
                                                    </div>

                                    {/* Yorum Formu */}
                                    <div className="comment-form-wrapper">
                                        <h3>Yorum Yap</h3>
                                        <form onSubmit={handleCommentSubmit} className="comment-form">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Adınız *"
                                                            value={newComment.name}
                                                            onChange={(e) => setNewComment({
                                                                ...newComment,
                                                                name: e.target.value
                                                            })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            placeholder="E-posta adresiniz *"
                                                            value={newComment.email}
                                                            onChange={(e) => setNewComment({
                                                                ...newComment,
                                                                email: e.target.value
                                                            })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <textarea
                                                    placeholder="Yorumunuz *"
                                                    value={newComment.message}
                                                    onChange={(e) => setNewComment({
                                                        ...newComment,
                                                        message: e.target.value
                                                    })}
                                                    required
                                                ></textarea>
                                            </div>
                                            <button type="submit" className="submit-btn">
                                                Yorum Gönder
                                            </button>
                                            </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer horizontal="vertical-footer"/>
        </Fragment>
    );
};

export default BlogDetails;















