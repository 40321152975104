import React, { useState, useEffect } from 'react';
import TestimonialModel from './TestimonialModel';
import './TestimonialAdmin.css';

function TestimonialAdmin() {
    const [testimonials, setTestimonials] = useState([]);
    const [newTestimonial, setNewTestimonial] = useState({
        name: '',
        position: '',
        image: '',
        comment: '',
        rating: 5
    });
    const [editingId, setEditingId] = useState(null);

    useEffect(() => {
        const savedTestimonials = localStorage.getItem('testimonials');
        if (savedTestimonials) {
            setTestimonials(JSON.parse(savedTestimonials));
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (editingId) {
            // Düzenleme modu
            const updatedTestimonials = testimonials.map(item => 
                item.id === editingId ? { ...item, ...newTestimonial } : item
            );
            setTestimonials(updatedTestimonials);
            localStorage.setItem('testimonials', JSON.stringify(updatedTestimonials));
            setEditingId(null);
        } else {
            // Yeni yorum ekleme
            const testimonial = {
                ...TestimonialModel,
                ...newTestimonial,
                id: Date.now()
            };
            const updatedTestimonials = [...testimonials, testimonial];
            setTestimonials(updatedTestimonials);
            localStorage.setItem('testimonials', JSON.stringify(updatedTestimonials));
        }

        setNewTestimonial({
            name: '',
            position: '',
            image: '',
            comment: '',
            rating: 5
        });
    };

    const handleEdit = (testimonial) => {
        setNewTestimonial(testimonial);
        setEditingId(testimonial.id);
    };

    const handleDelete = (id) => {
        if (window.confirm('Bu yorumu silmek istediğinize emin misiniz?')) {
            const updatedTestimonials = testimonials.filter(item => item.id !== id);
            setTestimonials(updatedTestimonials);
            localStorage.setItem('testimonials', JSON.stringify(updatedTestimonials));
        }
    };

    return (
        <div className="testimonial-admin">
            <h2>{editingId ? 'Yorumu Düzenle' : 'Yeni Yorum Ekle'}</h2>
            
            <form onSubmit={handleSubmit} className="testimonial-form">
                <div className="form-group">
                    <label>Müşteri Adı:</label>
                    <input
                        type="text"
                        value={newTestimonial.name}
                        onChange={(e) => setNewTestimonial({
                            ...newTestimonial,
                            name: e.target.value
                        })}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Pozisyon:</label>
                    <input
                        type="text"
                        value={newTestimonial.position}
                        onChange={(e) => setNewTestimonial({
                            ...newTestimonial,
                            position: e.target.value
                        })}
                        placeholder="Örn: Mutlu Müşteri"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Fotoğraf URL (Opsiyonel):</label>
                    <input
                        type="text"
                        value={newTestimonial.image}
                        onChange={(e) => setNewTestimonial({
                            ...newTestimonial,
                            image: e.target.value
                        })}
                    />
                </div>

                <div className="form-group">
                    <label>Yorum:</label>
                    <textarea
                        value={newTestimonial.comment}
                        onChange={(e) => setNewTestimonial({
                            ...newTestimonial,
                            comment: e.target.value
                        })}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Değerlendirme (1-5):</label>
                    <select
                        value={newTestimonial.rating}
                        onChange={(e) => setNewTestimonial({
                            ...newTestimonial,
                            rating: Number(e.target.value)
                        })}
                    >
                        {[1,2,3,4,5].map(num => (
                            <option key={num} value={num}>{num} Yıldız</option>
                        ))}
                    </select>
                </div>

                <button type="submit" className="submit-btn">
                    {editingId ? 'Güncelle' : 'Yorum Ekle'}
                </button>
            </form>

            <div className="testimonials-list">
                <h3>Mevcut Yorumlar</h3>
                {testimonials.map(testimonial => (
                    <div key={testimonial.id} className="testimonial-item">
                        <div className="testimonial-content">
                            <h4>{testimonial.name}</h4>
                            <p className="position">{testimonial.position}</p>
                            <p className="comment">{testimonial.comment}</p>
                            <div className="rating">
                                {'★'.repeat(testimonial.rating)}
                                {'☆'.repeat(5 - testimonial.rating)}
                            </div>
                        </div>
                        <div className="testimonial-actions">
                            <button onClick={() => handleEdit(testimonial)}>Düzenle</button>
                            <button onClick={() => handleDelete(testimonial.id)}>Sil</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TestimonialAdmin; 