import React, { useState, useEffect } from 'react';

function AppointmentList() {
    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        const savedAppointments = localStorage.getItem('appointments');
        if (savedAppointments) {
            setAppointments(JSON.parse(savedAppointments));
        }
    }, []);

    const handleStatusChange = (id, status) => {
        const updatedAppointments = appointments.map(app => 
            app.id === id ? { ...app, status } : app
        );
        setAppointments(updatedAppointments);
        localStorage.setItem('appointments', JSON.stringify(updatedAppointments));
    };

    return (
        <div className="appointments-section">
            <h2>Randevu Listesi</h2>
            <div className="appointments-grid">
                {appointments.map(appointment => (
                    <div key={appointment.id} className="appointment-item">
                        <div className="appointment-content">
                            <div className="appointment-header">
                                <h4>{appointment.name}</h4>
                                <span className={`status ${appointment.status || 'beklemede'}`}>
                                    {appointment.status || 'Beklemede'}
                                </span>
                            </div>
                            <div className="appointment-details">
                                <p><i className="fas fa-phone"></i> {appointment.phone}</p>
                                <p><i className="fas fa-calendar"></i> {appointment.date}</p>
                                <p><i className="fas fa-clock"></i> {appointment.time}</p>
                                <p><i className="fas fa-paw"></i> {appointment.petType}</p>
                            </div>
                            <div className="appointment-message">
                                <p>{appointment.message}</p>
                            </div>
                            <div className="appointment-actions">
                                <button 
                                    onClick={() => handleStatusChange(appointment.id, 'onaylandi')}
                                    className="status-btn approve"
                                >
                                    Onayla
                                </button>
                                <button 
                                    onClick={() => handleStatusChange(appointment.id, 'iptal')}
                                    className="status-btn reject"
                                >
                                    İptal Et
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AppointmentList; 