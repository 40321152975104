import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import BlogAdmin from './components/BlogAdmin/BlogAdmin';
import Blog from './components/Blog';
import Demo from './demo/Demo';
import DemoTF from './demo/DemoTF';
import BlogGrid from './pages/BlogGrid';
import HomeOlive from './pages/HomeOlive';
import BlogDetails from './pages/BlogDetails';
import BlogTwoColumn from './pages/BlogTwoColumn';
import HomeHorizontal from './pages/HomeHorizontal';
import TestimonialAdmin from './components/AdminTestimonials/TestimonialAdmin';
import AdminLogin from './components/AdminLogin/AdminLogin';

// Protected Route bileşeni
const ProtectedRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem('isAdminLoggedIn') === 'true';
    
    if (!isAuthenticated) {
        return <Navigate to="/admin/login" />;
    }
    
    return children;
};

const App = () => {
    const [settings, setSettings] = useState({
        title: 'Pet Kuaför | Pet Bakım Hizmetleri',
        description: 'Pet kuaför, evcil hayvanların hijyen, sağlık ve estetik ihtiyaçlarını karşılamak için özel olarak tasarlanmış bakım hizmetlerini sunan bir meslektir.'
    });

    useEffect(() => {
        const savedSettings = localStorage.getItem('siteSettings');
        if (savedSettings) {
            const parsedSettings = JSON.parse(savedSettings);
            setSettings({
                title: parsedSettings.title || settings.title,
                description: parsedSettings.description || settings.description
            });

            // Google Analytics kodunu ekle
            if (parsedSettings.googleAnalytics) {
                const script = document.createElement('script');
                script.async = true;
                script.src = `https://www.googletagmanager.com/gtag/js?id=${parsedSettings.googleAnalytics}`;
                document.head.appendChild(script);

                window.dataLayer = window.dataLayer || [];
                window.gtag = function(){window.dataLayer.push(arguments);};
                window.gtag('js', new Date());
                window.gtag('config', parsedSettings.googleAnalytics);
            }
        }
    }, []);

    return (
        <HelmetProvider>
            <BrowserRouter>
                <Helmet>
                    <title>{settings.title}</title>
                    <meta name="description" content={settings.description} />
                </Helmet>
                
                <div className="App">
                    <Routes>
                        <Route path="/" element={<HomeOlive />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/blog/:slug" element={<BlogDetails />} />
                        <Route path="/blog-grid" element={<BlogGrid />} />
                        <Route path="/blog-two-column" element={<BlogTwoColumn />} />
                        
                        {/* Admin routes */}
                        <Route path="/admin/login" element={<AdminLogin />} />
                        <Route 
                            path="/admin/blog" 
                            element={
                                <ProtectedRoute>
                                    <BlogAdmin />
                                </ProtectedRoute>
                            } 
                        />
                        
                        {/* Diğer sayfalar */}
                        <Route path="/demo" element={<Demo />} />
                        <Route path="/demo-tf" element={<DemoTF />} />
                        <Route path="/home-horizontal" element={<HomeHorizontal />} />
                    </Routes>
                </div>
            </BrowserRouter>
        </HelmetProvider>
    );
}

export default App;
