import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function BlogForm() {
    const [post, setPost] = useState({
        title: '',
        desc: '',
        image: '',
        postBy: '',
        date: new Date().toLocaleDateString('tr-TR')
    });

    const [imagePreview, setImagePreview] = useState(null);

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'color', 'background',
        'link', 'image'
    ];

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPost({ ...post, image: reader.result });
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const savedPosts = localStorage.getItem('blogPosts');
        const posts = savedPosts ? JSON.parse(savedPosts) : [];
        
        const newPost = {
            ...post,
            id: Date.now()
        };
        
        posts.push(newPost);
        localStorage.setItem('blogPosts', JSON.stringify(posts));
        
        // Formu temizle
        setPost({
            title: '',
            desc: '',
            image: '',
            postBy: '',
            date: new Date().toLocaleDateString('tr-TR')
        });
        setImagePreview(null);
    };

    return (
        <div className="blog-form">
            <h2>Yeni Blog Yazısı</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Başlık:</label>
                    <input
                        type="text"
                        value={post.title}
                        onChange={(e) => setPost({...post, title: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>İçerik:</label>
                    <ReactQuill 
                        theme="snow"
                        value={post.desc}
                        onChange={(content) => setPost({...post, desc: content})}
                        modules={modules}
                        formats={formats}
                        style={{ height: '300px', marginBottom: '50px' }}
                    />
                </div>

                <div className="form-group">
                    <label>Resim:</label>
                    <div className="image-upload-container">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="image-upload-input"
                            required
                        />
                        {imagePreview && (
                            <div className="image-preview">
                                <img src={imagePreview} alt="Preview" />
                            </div>
                        )}
                    </div>
                </div>

                <div className="form-group">
                    <label>Yazar:</label>
                    <input
                        type="text"
                        value={post.postBy}
                        onChange={(e) => setPost({...post, postBy: e.target.value})}
                        required
                    />
                </div>

                <button type="submit" className="submit-btn">Blog Yazısı Ekle</button>
            </form>
        </div>
    );
}

export default BlogForm; 