import React, { useState, useEffect } from "react";
import "./Reviews.css";

const Reviews = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        const savedTestimonials = localStorage.getItem('testimonials');
        if (savedTestimonials) {
            setTestimonials(JSON.parse(savedTestimonials));
        }
    }, []);

    return (
        <div className="app-reviews" id="yorumlar">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center mb--80">
                            <h2>Müşteri Yorumları</h2>
                            <p>Değerli müşterilerimizin bizim hakkımızda düşünceleri</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {testimonials.map(testimonial => (
                        <div key={testimonial.id} className="col-lg-4 col-md-6 col-12">
                            <div className="review">
                                <div className="review-content">
                                    <div className="rating">
                                        {[...Array(testimonial.rating)].map((_, i) => (
                                            <i key={i} className="fas fa-star"></i>
                                        ))}
                                    </div>
                                    <p className="comment">{testimonial.comment}</p>
                                    <div className="reviewer">
                                        <div className="reviewer-image">
                                            {testimonial.image && (
                                                <img 
                                                    src={testimonial.image} 
                                                    alt={testimonial.name}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="reviewer-info">
                                            <h4>{testimonial.name}</h4>
                                            <p>{testimonial.position}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Reviews; 