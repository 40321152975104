import React, { useState, useEffect, Fragment } from "react";
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import { Helmet } from 'react-helmet-async';

const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);

    useEffect(() => {
        const loadPosts = () => {
            const savedPosts = localStorage.getItem('blogPosts');
            if (savedPosts) {
                setBlogPosts(JSON.parse(savedPosts));
            }
        };

        loadPosts();
        window.addEventListener('storage', loadPosts);
        
        return () => {
            window.removeEventListener('storage', loadPosts);
        };
    }, []);

    const truncateText = (text, maxLength = 100) => {
        const strippedText = text.replace(/<[^>]+>/g, '');
        if (strippedText.length <= maxLength) return strippedText;
        return strippedText.substr(0, maxLength) + '...';
    };

    const createSEFUrl = (title) => {
        return title
            .toLowerCase()
            .replace(/[ğ]/g, 'g')
            .replace(/[ü]/g, 'u')
            .replace(/[ş]/g, 's')
            .replace(/[ı]/g, 'i')
            .replace(/[ö]/g, 'o')
            .replace(/[ç]/g, 'c')
            .replace(/[^a-z0-9]/g, '-')
            .replace(/-+/g, '-')
            .replace(/^-|-$/g, '');
    };

    const DataList = blogPosts.map((val) => {
        const sefUrl = createSEFUrl(val.title);
        return (
            <div className="col-lg-4 col-md-6 col-12" key={val.id}>
                <div className="blog">
                    <div className="thumb">
                        <Link to={`/blog/${sefUrl}`}>
                            <div className="image-container">
                                <img 
                                    src={val.image}
                                    alt="Blog thumb" 
                                />
                            </div>
                        </Link>
                    </div>
                    <div className="inner">
                        <h4 className="title">
                            <Link to={`/blog/${sefUrl}`}>
                                {val.title.length > 50 ? val.title.substring(0, 50) + '...' : val.title}
                            </Link>
                        </h4>
                        <ul className="meta">
                            <li>{val.date}</li>
                            <li>Yazar <a href="/">{val.postBy}</a></li>
                        </ul>
                        <div className="desc-container">
                            <p className="desc mt--10 mb--30">
                                {truncateText(val.desc)}
                            </p>
                        </div>
                        <div className="blog-btn">
                            <Link className="button-link" to={`/blog/${sefUrl}`}>
                                Devamı...
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    });

    return (
        <Fragment>
            <Navbar />
            <div className="blog-area pt--120 pt_md--80 pt_sm--80" style={{backgroundColor: '#f8f9fc'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--80">
                                <h2>Pet Kuaför Yazılar</h2>
                                <p>İstanbul Pet kuaför sitemizde yayınlanan makaleler tamamiyle bilgilendirici amaçlıdır.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {DataList}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Blog;