import React, { useState, useEffect } from 'react';

function BlogList() {
    const [posts, setPosts] = useState([]);
    const [editingPost, setEditingPost] = useState(null);

    useEffect(() => {
        const savedPosts = localStorage.getItem('blogPosts');
        if (savedPosts) {
            setPosts(JSON.parse(savedPosts));
        }
    }, []);

    const handleDelete = (id) => {
        if (window.confirm('Bu blog yazısını silmek istediğinize emin misiniz?')) {
            const updatedPosts = posts.filter(post => post.id !== id);
            setPosts(updatedPosts);
            localStorage.setItem('blogPosts', JSON.stringify(updatedPosts));
        }
    };

    const handleEdit = (post) => {
        setEditingPost(post);
        // Düzenleme formunu göster veya modal aç
    };

    return (
        <div className="blog-list">
            <h2>Blog Yazıları</h2>
            <div className="blog-grid">
                {posts.map(post => (
                    <div key={post.id} className="blog-item">
                        <div className="blog-item-image">
                            <img src={post.image} alt={post.title} />
                        </div>
                        <div className="blog-item-content">
                            <h3>{post.title}</h3>
                            <p className="blog-meta">
                                <span>{post.date}</span>
                                <span>Yazar: {post.postBy}</span>
                            </p>
                        </div>
                        <div className="blog-item-actions">
                            <button 
                                className="edit-btn"
                                onClick={() => handleEdit(post)}
                            >
                                Düzenle
                            </button>
                            <button 
                                className="delete-btn"
                                onClick={() => handleDelete(post.id)}
                            >
                                Sil
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BlogList; 