import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css';

const AdminLogin = () => {
    const [credentials, setCredentials] = useState({
        username: '',
        password: ''
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Burada gerçek bir API çağrısı yapılabilir
        if (credentials.username === 'admin' && credentials.password === 'amen123amen') {
            localStorage.setItem('isAdminLoggedIn', 'true');
            navigate('/admin/blog');
        } else {
            setError('Kullanıcı adı veya şifre hatalı!');
        }
    };

    return (
        <div className="admin-login-container">
            <div className="admin-login-box">
                <h2>Admin Girişi</h2>
                {error && <div className="error-message">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Kullanıcı Adı:</label>
                        <input
                            type="text"
                            value={credentials.username}
                            onChange={(e) => setCredentials({
                                ...credentials,
                                username: e.target.value
                            })}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Şifre:</label>
                        <input
                            type="password"
                            value={credentials.password}
                            onChange={(e) => setCredentials({
                                ...credentials,
                                password: e.target.value
                            })}
                            required
                        />
                    </div>
                    <button type="submit">Giriş Yap</button>
                </form>
            </div>
        </div>
    );
};

export default AdminLogin; 