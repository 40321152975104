import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlogForm from './BlogForm';
import BlogList from './BlogList';
import TestimonialAdmin from '../AdminTestimonials/TestimonialAdmin';
import AppointmentList from './AppointmentList';
import HairdresserList from './HairdresserList';
import AdminSettings from '../AdminSettings/AdminSettings';
import './BlogAdmin.css';

function BlogAdmin() {
    const [activeTab, setActiveTab] = useState('blog'); // 'blog', 'testimonials', 'appointments', 'hairdressers', 'settings'
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('isAdminLoggedIn');
        navigate('/admin/login');
    };

    return (
        <div className="admin-panel">
            <div className="admin-header">
                <div className="admin-header-top">
                    <h1>Admin Panel</h1>
                    <button 
                        className="logout-btn"
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt"></i> Çıkış Yap
                    </button>
                </div>
                <div className="admin-tabs">
                    <button 
                        className={`tab-button ${activeTab === 'blog' ? 'active' : ''}`}
                        onClick={() => setActiveTab('blog')}
                    >
                        Blog Yönetimi
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'testimonials' ? 'active' : ''}`}
                        onClick={() => setActiveTab('testimonials')}
                    >
                        Müşteri Yorumları
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'appointments' ? 'active' : ''}`}
                        onClick={() => setActiveTab('appointments')}
                    >
                        Randevular
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'hairdressers' ? 'active' : ''}`}
                        onClick={() => setActiveTab('hairdressers')}
                    >
                        Kuaför Listesi
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'settings' ? 'active' : ''}`}
                        onClick={() => setActiveTab('settings')}
                    >
                        Site Ayarları
                    </button>
                </div>
            </div>

            {activeTab === 'blog' && (
                <div className="blog-management">
                    <BlogForm />
                    <BlogList />
                </div>
            )}

            {activeTab === 'testimonials' && (
                <div className="testimonials-management">
                    <TestimonialAdmin />
                </div>
            )}

            {activeTab === 'appointments' && (
                <div className="appointments-management">
                    <AppointmentList />
                </div>
            )}

            {activeTab === 'hairdressers' && (
                <div className="hairdressers-management">
                    <HairdresserList />
                </div>
            )}

            {activeTab === 'settings' && (
                <div className="settings-management">
                    <AdminSettings />
                </div>
            )}
        </div>
    );
}

export default BlogAdmin; 