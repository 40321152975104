import React, { Fragment } from "react";
import Navbar from "../components/Navbar";
import HeroOlive from "../components/HeroOlive";
import About from "../components/About";
import Reviews from "../components/Reviews";
import Blog from "../components/Blog";
import Footer from "../components/Footer";
import AppointmentForm from "../components/AppointmentForm/AppointmentForm";

const HomeOlive = () => {
    return (
        <Fragment>
            <Navbar />
            <HeroOlive bgshape="bg-shape" horizontal=""/>
            <About horizontalabout="horizontal-about"/>
            <div className="container-fluid" id="randevu">
                <div className="container">
                    <AppointmentForm />
                </div>
            </div>
            <Reviews />
            <Blog />
            <Footer horizontal="vertical-footer"/>
        </Fragment>
    );
}

export default HomeOlive;

