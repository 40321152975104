import React, { useState, useEffect } from 'react';
import styles from './AppointmentForm.module.css';

const AppointmentForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        pet_cinsi: '',
        pet_irk: '',
        pet_yas: '',
        kuafor_adi: '',
        date: '',
        time: '',
        message: ''
    });
    const [petImage, setPetImage] = useState(null);
    const [hairdressers, setHairdressers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState({ type: '', message: '' });
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

    // Sabit zaman aralıkları
    const START_TIME = '09:00';
    const END_TIME = '18:30';
    const SLOT_DURATION = 30; // dakika

    // Tüm zaman aralıklarını oluştur
    const generateTimeSlots = () => {
        const slots = [];
        let currentTime = new Date(`2024-01-01 ${START_TIME}`);
        const endTime = new Date(`2024-01-01 ${END_TIME}`);

        while (currentTime <= endTime) {
            slots.push(currentTime.toLocaleTimeString('tr-TR', { 
                hour: '2-digit', 
                minute: '2-digit',
                hour12: false 
            }));
            currentTime.setMinutes(currentTime.getMinutes() + SLOT_DURATION);
        }
        return slots;
    };

    // Seçilen tarih ve kuaföre göre müsait saatleri kontrol et
    const checkAvailableSlots = (date, kuaforId) => {
        const allSlots = generateTimeSlots();
        const existingAppointments = JSON.parse(localStorage.getItem('appointments') || '[]');
        
        // Seçilen tarih ve kuaföre ait randevuları filtrele
        const dayAppointments = existingAppointments.filter(app => 
            app.date === date && 
            app.kuafor === formData.kuafor_adi
        );

        // Dolu saatleri çıkar
        const availableSlots = allSlots.filter(time => 
            !dayAppointments.some(app => app.time === time)
        );

        setAvailableTimeSlots(availableSlots);
    };

    // Tarih veya kuaför seçimi değiştiğinde
    useEffect(() => {
        if (formData.date && formData.kuafor_adi) {
            checkAvailableSlots(formData.date, formData.kuafor_adi);
        }
    }, [formData.date, formData.kuafor_adi]);

    // Minimum tarih kontrolü
    const getMinDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    // Input değişiklik handler'ını güncelle
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
            // Eğer kuaför veya tarih değişirse, saat seçimini sıfırla
            ...(name === 'kuafor_adi' || name === 'date' ? { time: '' } : {})
        }));
    };

    useEffect(() => {
        // Aktif kuaförleri yükle
        const savedHairdressers = localStorage.getItem('hairdressers');
        if (savedHairdressers) {
            const allHairdressers = JSON.parse(savedHairdressers);
            // Sadece aktif kuaförleri göster
            const activeHairdressers = allHairdressers.filter(h => h.isActive);
            setHairdressers(activeHairdressers);
        }
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5242880) {
                alert('Dosya boyutu 5MB\'dan küçük olmalıdır!');
                e.target.value = '';
                return;
            }
            setPetImage(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        try {
            const newAppointment = {
                id: Date.now(),
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                date: formData.date,
                time: formData.time,
                petType: formData.pet_cinsi,
                petBreed: formData.pet_irk,
                petAge: formData.pet_yas,
                kuafor: formData.kuafor_adi,
                message: formData.message,
                status: 'Beklemede',
                createdAt: new Date().toLocaleString('tr-TR')
            };

            // Mevcut randevuları al
            const existingAppointments = JSON.parse(localStorage.getItem('appointments') || '[]');
            
            // Yeni randevuyu ekle
            const updatedAppointments = [...existingAppointments, newAppointment];
            
            // LocalStorage'a kaydet
            localStorage.setItem('appointments', JSON.stringify(updatedAppointments));

            // Form alanlarını temizle
            setFormData({
                name: '',
                email: '',
                phone: '',
                pet_cinsi: '',
                pet_irk: '',
                pet_yas: '',
                kuafor_adi: '',
                date: '',
                time: '',
                message: ''
            });
            setPetImage(null);

            // Başarı mesajını göster
            setModalMessage({ 
                type: 'success', 
                message: 'Randevunuz başarıyla alındı! En kısa sürede size dönüş yapacağız.' 
            });
            setShowModal(true);

            // 3 saniye sonra modalı kapat
            setTimeout(() => {
                setShowModal(false);
            }, 3000);

        } catch (error) {
            // Hata mesajını göster
            setModalMessage({ 
                type: 'error', 
                message: 'Randevu alınırken bir hata oluştu. Lütfen tekrar deneyin.' 
            });
            setShowModal(true);

            setTimeout(() => {
                setShowModal(false);
            }, 3000);
        }
    };

    return (
        <section className={styles.appointment}>
            <h2>Randevu Al</h2>
            <form onSubmit={handleSubmit}>
                <div className={styles.formGrid}>
                    <div className={styles.formGroup}>
                        <label htmlFor="name">Ad Soyad:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className={styles.formControl}
                            required
                        />
                    </div>
    
                    <div className={styles.formGroup}>
                        <label htmlFor="email">E-posta:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className={styles.formControl}
                            required
                        />
                    </div>
    
                    <div className={styles.formGroup}>
                        <label htmlFor="phone">Telefon:</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            className={styles.formControl}
                            required
                        />
                    </div>
    
                    <div className={styles.formGroup}>
                        <label htmlFor="pet_cinsi">Pet Cinsi:</label>
                        <select
                            id="pet_cinsi"
                            name="pet_cinsi"
                            value={formData.pet_cinsi}
                            onChange={handleInputChange}
                            className={styles.formControl}
                            required
                        >
                            <option value="">Seçiniz</option>
                            <option value="kedi">Kedi</option>
                            <option value="köpek">Köpek</option>
                        </select>
                    </div>
    
                    <div className={styles.formGroup}>
                        <label htmlFor="pet_irk">Pet Irkı:</label>
                        <input
                            type="text"
                            id="pet_irk"
                            name="pet_irk"
                            value={formData.pet_irk}
                            onChange={handleInputChange}
                            className={styles.formControl}
                            required
                        />
                    </div>
    
                    <div className={styles.formGroup}>
                        <label htmlFor="pet_yas">Pet Yaşı:</label>
                        <input
                            type="number"
                            id="pet_yas"
                            name="pet_yas"
                            value={formData.pet_yas}
                            onChange={handleInputChange}
                            className={styles.formControl}
                            required
                        />
                    </div>
    
                    <div className={styles.formGroup}>
                        <label htmlFor="kuafor_adi">Kuaför:</label>
                        <select
                            id="kuafor_adi"
                            name="kuafor_adi"
                            value={formData.kuafor_adi}
                            onChange={handleInputChange}
                            className={styles.formControl}
                            required
                        >
                            <option value="">Kuaför seçiniz</option>
                            {hairdressers.map((kuafor) => (
                                <option key={kuafor.id} value={kuafor.name}>
                                    {kuafor.name} - {kuafor.specialty}
                                </option>
                            ))}
                        </select>
                    </div>
    
                    <div className={styles.formGroup}>
                        <label htmlFor="date">Randevu Tarihi:</label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={formData.date}
                            onChange={handleInputChange}
                            className={styles.formControl}
                            min={getMinDate()}
                            required
                        />
                    </div>
    
                    <div className={styles.formGroup}>
                        <label htmlFor="time">Randevu Saati:</label>
                        <select
                            id="time"
                            name="time"
                            value={formData.time}
                            onChange={handleInputChange}
                            className={styles.formControl}
                            required
                            disabled={!formData.date || !formData.kuafor_adi}
                        >
                            <option value="">Saat seçiniz</option>
                            {availableTimeSlots.map(time => (
                                <option key={time} value={time}>
                                    {time}
                                </option>
                            ))}
                        </select>
                        {(!formData.date || !formData.kuafor_adi) && (
                            <small className={styles.formText}>
                                Saat seçimi için önce tarih ve kuaför seçmelisiniz.
                            </small>
                        )}
                        {formData.date && formData.kuafor_adi && availableTimeSlots.length === 0 && (
                            <small className={styles.formText}>
                                Seçilen tarihte müsait saat bulunmamaktadır.
                            </small>
                        )}
                    </div>
                </div>
    
                <div className={styles.formGroup}>
                    <label htmlFor="pet_resim">Pet Resmi (Sadece JPG ve HEIC formatı):</label>
                    <input
                        type="file"
                        id="pet_resim"
                        name="pet_resim"
                        onChange={handleFileChange}
                        className={styles.formControl}
                        accept=".jpg,.jpeg,.heic"
                    />
                    <small className={styles.formText}>Maximum dosya boyutu: 5MB</small>
                </div>
    
                <div className={styles.formGroup}>
                    <label htmlFor="message">Notlar:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        className={styles.formControl}
                        rows="4"
                    />
                </div>
    
                <button type="submit" className={styles.cta}>Randevu Al</button>
            </form>
    
            {showModal && (
                <div className={`${styles.splashModal} ${styles.show}`}>
                    <div className={styles.splashContent}>
                        <div className={styles.splashIcon}>
                            <i className={modalMessage.type === 'success' ? styles.successIcon : styles.errorIcon}>
                                {modalMessage.type === 'success' ? '✓' : '✕'}
                            </i>
                        </div>
                        <div className={styles.splashMessage}>{modalMessage.message}</div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default AppointmentForm; 