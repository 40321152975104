import React, { useState, useEffect } from 'react';
import './AdminSettings.css';

const AdminSettings = () => {
    const [settings, setSettings] = useState({
        title: '',
        description: '',
        googleAnalytics: '',
        socialMedia: {
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            linkedin: ''
        }
    });

    useEffect(() => {
        // Kayıtlı ayarları yükle
        const savedSettings = localStorage.getItem('siteSettings');
        if (savedSettings) {
            setSettings(JSON.parse(savedSettings));
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem('siteSettings', JSON.stringify(settings));
        alert('Ayarlar başarıyla kaydedildi!');
    };

    const handleSocialMediaChange = (platform, value) => {
        setSettings(prev => ({
            ...prev,
            socialMedia: {
                ...prev.socialMedia,
                [platform]: value
            }
        }));
    };

    return (
        <div className="admin-settings">
            <h2>Site Ayarları</h2>
            
            <form onSubmit={handleSubmit}>
                <div className="settings-section">
                    <h3>SEO Ayarları</h3>
                    <div className="form-group">
                        <label>Site Başlığı:</label>
                        <input
                            type="text"
                            value={settings.title}
                            onChange={(e) => setSettings({...settings, title: e.target.value})}
                            placeholder="Pet Kuaför | Pet Bakım Hizmetleri"
                        />
                    </div>

                    <div className="form-group">
                        <label>Site Açıklaması:</label>
                        <textarea
                            value={settings.description}
                            onChange={(e) => setSettings({...settings, description: e.target.value})}
                            placeholder="Site açıklaması..."
                            rows="3"
                        />
                    </div>

                    <div className="form-group">
                        <label>Google Analytics Kodu:</label>
                        <input
                            type="text"
                            value={settings.googleAnalytics}
                            onChange={(e) => setSettings({...settings, googleAnalytics: e.target.value})}
                            placeholder="UA-XXXXXXXXX-X"
                        />
                    </div>
                </div>

                <div className="settings-section">
                    <h3>Sosyal Medya Linkleri</h3>
                    <div className="form-group">
                        <label>Facebook:</label>
                        <input
                            type="url"
                            value={settings.socialMedia.facebook}
                            onChange={(e) => handleSocialMediaChange('facebook', e.target.value)}
                            placeholder="https://facebook.com/..."
                        />
                    </div>

                    <div className="form-group">
                        <label>Twitter:</label>
                        <input
                            type="url"
                            value={settings.socialMedia.twitter}
                            onChange={(e) => handleSocialMediaChange('twitter', e.target.value)}
                            placeholder="https://twitter.com/..."
                        />
                    </div>

                    <div className="form-group">
                        <label>Instagram:</label>
                        <input
                            type="url"
                            value={settings.socialMedia.instagram}
                            onChange={(e) => handleSocialMediaChange('instagram', e.target.value)}
                            placeholder="https://instagram.com/..."
                        />
                    </div>

                    <div className="form-group">
                        <label>YouTube:</label>
                        <input
                            type="url"
                            value={settings.socialMedia.youtube}
                            onChange={(e) => handleSocialMediaChange('youtube', e.target.value)}
                            placeholder="https://youtube.com/..."
                        />
                    </div>

                    <div className="form-group">
                        <label>LinkedIn:</label>
                        <input
                            type="url"
                            value={settings.socialMedia.linkedin}
                            onChange={(e) => handleSocialMediaChange('linkedin', e.target.value)}
                            placeholder="https://linkedin.com/..."
                        />
                    </div>
                </div>

                <button type="submit" className="save-btn">Ayarları Kaydet</button>
            </form>
        </div>
    );
};

export default AdminSettings; 