import React, { useState, useEffect } from 'react';

function HairdresserList() {
    const [hairdressers, setHairdressers] = useState([]);
    const [newHairdresser, setNewHairdresser] = useState({
        name: '',
        position: '',
        status: 'active'
    });

    useEffect(() => {
        const savedHairdressers = localStorage.getItem('hairdressers');
        if (savedHairdressers) {
            setHairdressers(JSON.parse(savedHairdressers));
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const hairdresser = {
            ...newHairdresser,
            id: Date.now()
        };
        const updatedHairdressers = [...hairdressers, hairdresser];
        setHairdressers(updatedHairdressers);
        localStorage.setItem('hairdressers', JSON.stringify(updatedHairdressers));
        setNewHairdresser({ name: '', position: '', status: 'active' });
    };

    const handleStatusChange = (id, status) => {
        const updatedHairdressers = hairdressers.map(h => 
            h.id === id ? { ...h, status } : h
        );
        setHairdressers(updatedHairdressers);
        localStorage.setItem('hairdressers', JSON.stringify(updatedHairdressers));
    };

    return (
        <div className="hairdressers-section">
            <h2>Kuaför Listesi</h2>
            
            <form onSubmit={handleSubmit} className="hairdresser-form">
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Kuaför Adı"
                        value={newHairdresser.name}
                        onChange={(e) => setNewHairdresser({...newHairdresser, name: e.target.value})}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Pozisyon"
                        value={newHairdresser.position}
                        onChange={(e) => setNewHairdresser({...newHairdresser, position: e.target.value})}
                        required
                    />
                </div>
                <button type="submit">Kuaför Ekle</button>
            </form>

            <div className="hairdressers-grid">
                {hairdressers.map(hairdresser => (
                    <div key={hairdresser.id} className="hairdresser-item">
                        <div className="hairdresser-content">
                            <h4>{hairdresser.name}</h4>
                            <p>{hairdresser.position}</p>
                            <span className={`status-badge ${hairdresser.status}`}>
                                {hairdresser.status === 'active' ? 'Aktif' : 'Pasif'}
                            </span>
                        </div>
                        <div className="hairdresser-actions">
                            <button
                                className={`status-btn ${hairdresser.status === 'active' ? 'deactivate' : 'activate'}`}
                                onClick={() => handleStatusChange(
                                    hairdresser.id,
                                    hairdresser.status === 'active' ? 'inactive' : 'active'
                                )}
                            >
                                {hairdresser.status === 'active' ? 'Pasife Al' : 'Aktife Al'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HairdresserList; 