import React from "react";
import Slider from "react-slick"

import {testimonial, testimonial2} from "./script";

class Testimonial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          nav1: null,
          nav2: null
        };
    }

    componentDidMount() {
        this.setState({
          nav1: this.testimonial,
          nav2: this.testimonial2
        });
    }


    render(){
        return(
            <div className="testimonial-wrapper pt--120 text-center" id="yorumlar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="testimonial-activation">
                                <div className="section-title text-center mb--80">
                                    <h2><span className="theme-color">Müşteri Yorumları</span></h2>
                                    <p>Müşterilerimizin ve sevimli dostlarının mutluluğu bizim için her şeyden önemli!</p>
                                    <h3>Patili Dostlarınızın Gülümsemesi Bizim Başarımız!</h3>
                                    <p>Pet Kuaför deneyimini yaşamış dostlarımızdan gelen samimi yorumlar. Profesyonel ekibimizin sevgi dolu yaklaşımı ve kaliteli hizmet anlayışıyla siz de ailenizin en özel üyelerine hak ettikleri bakımı sunabilirsiniz. Çünkü onlar, en iyisini hak ediyor!</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">

                                        <Slider {...testimonial} asNavFor={this.state.nav2} ref={slider => (this.testimonial = slider)} className="testimonial-image-slider text-center">
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/1.png')} alt="testimonial 1" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/1.png')} alt="testimonial 2" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/2.png')} alt="testimonial 3" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/3.png')} alt="testimonial 2" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/2.png')} alt="testimonial 3" />
                                            </div>
                                        </Slider>

                                    </div>
                                </div>

                                <Slider {...testimonial2} asNavFor={this.state.nav1} ref={slider => (this.testimonial2 = slider)} className="testimonial-text-slider text-center">
                                    <div className="sin-testiText">
                                        <h2>M S Nawaz </h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>Chowchilla Madera</h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>Nam nec tellus a odio tincidunt This lorem is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean nisi sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum gravida.</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>Kattie Luis</h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>Nam nec tellus a odio tincidunt This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean tincidunt sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum Photoshop.</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>Kattie Luis</h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>Nam nec tellus a odio tincidunt This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean tincidunt sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum Photoshop.</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>Kattie Luis</h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>Nam nec tellus a odio tincidunt This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean tincidunt sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum Photoshop.</p>
                                    </div>
                                </Slider>
                                    
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
 export default Testimonial;







