import React, { useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    const location = useLocation();
    const isBlogPage = location.pathname.includes('/blog/');

    useEffect(() => {
        const header = document.querySelector('.app-header');
        
        if (isBlogPage) {
            // Blog sayfasında sabit renk
            header.classList.remove('header--transparent');
            header.classList.add('header--sticky');
        } else {
            // Ana sayfada scroll efekti
            const handleScroll = () => {
                if (window.scrollY > 10) {
                    header.classList.add('header--sticky');
                    header.classList.remove('header--transparent');
                } else {
                    header.classList.remove('header--sticky');
                    header.classList.add('header--transparent');
                }
            };

            // Sayfa yüklendiğinde transparent olsun
            header.classList.add('header--transparent');
            header.classList.remove('header--sticky');

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [isBlogPage]);

    const renderLink = (to, text) => {
        if (location.pathname === '/') {
            // Ana sayfada anchor link
            return <a href={`#${to}`}>{text}</a>;
        } else {
            // Diğer sayfalarda ana sayfaya yönlendirme
            return <Link to={`/${to === 'home' ? '' : to}`}>{text}</Link>;
        }
    };

    return(
        <div className="app-header" id="main-menu">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-sm-5 col-5">
                        <div className="logo">
                            <Link to="/">
                                <img className="logo-1" src={require('../assets/images/logo/logo.png')} alt="pet kuaför"/>
                                <img className="logo-2" src={require('../assets/images/logo/logo-2.png')} alt="pet kuaför"/>
                                <img className="logo-3" src={require('../assets/images/logo/logo-3.png')} alt="pet kuaför"/>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-8 d-none d-lg-block">
                        <div className="mainmenu-wrapper">
                            <nav>
                                <ul className="main-menu">
                                    <li className="active">{renderLink('home', 'Anasayfa')}</li>
                                    <li>{renderLink('hakkimizda', 'Hakkımızda')}</li>
                                    <li>{renderLink('randevu', 'Randevu')}</li>
                                    <li>{renderLink('yorumlar', 'Yorumlar')}</li>
                                    <li>{renderLink('iletisim', 'İletişim')}</li>
                                </ul>
                            </nav>
                            <button className="button-default button-olive" type="button">WhatsApp</button>
                        </div>
                    </div>
                    <div className="col-sm-7 col-7 d-block d-lg-none">
                        <div className="mobile-menu">
                            <nav>
                                <ul>
                                    <li className="active">{renderLink('home', 'Anasayfa')}</li>
                                    <li>{renderLink('hakkimizda', 'Hakkımızda')}</li>
                                    <li>{renderLink('randevu', 'Randevu')}</li>
                                    <li>{renderLink('yorumlar', 'Yorumlar')}</li>
                                    <li>{renderLink('iletisim', 'İletişim')}</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;