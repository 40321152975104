const testimonialModel = {
    id: Date.now(),
    name: '',
    position: '', // Örn: "Mutlu Müşteri"
    image: '',    // Müşteri fotoğrafı (opsiyonel)
    comment: '',  // Yorum metni
    rating: 5,    // Yıldız sayısı (1-5)
    date: new Date().toLocaleDateString('tr-TR')
};

export default testimonialModel; 