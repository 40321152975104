import React, { useState, useEffect } from "react";

const Footer = ({ horizontal }) => {
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        const savedSettings = localStorage.getItem('siteSettings');
        if (savedSettings) {
            setSettings(JSON.parse(savedSettings));
        }
    }, []);

    return (
        <div>
            <div className={`footer-area ${horizontal}`} id="iletisim">
                <div className="footer-bg"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2 className="text-white"><span className="theme-color">İLETİŞİM</span></h2>
                                <p className="text-white">Patili dostlarınızın güzelliği ve saglığı bizim için önemli. Onları bize emanet edin, hak ettikleri özeni gösterelim!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-xl-4 offset-xl-1">
                            <div className="contact-inner">
                                <h3 className="title">İletişim Formu!</h3>
                                <form className="contact-form" action="/">
                                    <div className="input-box">
                                        <input type="text" placeholder="isminiz"/>
                                    </div>

                                    <div className="input-box">
                                        <input type="text" placeholder="mail adresiniz"/>
                                    </div>

                                    <div className="input-box">
                                        <textarea placeholder="mesajınız"></textarea>
                                    </div>

                                    <div className="input-box">
                                        <button className="submite-button" type="submit">Gönder</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-2 col-xl-4 offset-xl-2 mt_md--40 mt_sm--40">
                            <div className="contact-inner">
                                <h3 className="title">Adres Bilgileri</h3>
                                <div className="conatct-info">
                                    <div className="single-contact-info">
                                        <div className="contact-icon">
                                            <i className="zmdi zmdi-phone"></i>
                                        </div>
                                        <div className="contact-text">
                                            <span>+012 345 678 102<br />
                                            +012 345 678 102</span>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="contact-icon">
                                            <i className="zmdi zmdi-globe-alt"></i>
                                        </div>
                                        <div className="contact-text">
                                            <span>mailto:info@petkuafor.com.tr<br />
                                            https://petkuafor.com.tr/</span>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="contact-icon">
                                            <i className="zmdi zmdi-pin"></i>
                                        </div>
                                        <div className="contact-text">
                                            <span>Altayçeşme, Bağdat Cad. Dostlar Apt<br /> No:311-313/A, 34843 Maltepe/İstanbul</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-xl-4 offset-xl-4">
                            <div className="footer-links text-center">
                                {settings?.socialMedia?.facebook && (
                                    <a href={settings.socialMedia.facebook} target="_blank" rel="noopener noreferrer">
                                        <i className="zmdi zmdi-facebook"></i>
                                    </a>
                                )}
                                {settings?.socialMedia?.twitter && (
                                    <a href={settings.socialMedia.twitter} target="_blank" rel="noopener noreferrer">
                                        <i className="zmdi zmdi-twitter"></i>
                                    </a>
                                )}
                                {settings?.socialMedia?.instagram && (
                                    <a href={settings.socialMedia.instagram} target="_blank" rel="noopener noreferrer">
                                        <i className="zmdi zmdi-instagram"></i>
                                    </a>
                                )}
                                {settings?.socialMedia?.linkedin && (
                                    <a href={settings.socialMedia.linkedin} target="_blank" rel="noopener noreferrer">
                                        <i className="zmdi zmdi-linkedin"></i>
                                    </a>
                                )}
                                {settings?.socialMedia?.youtube && (
                                    <a href={settings.socialMedia.youtube} target="_blank" rel="noopener noreferrer">
                                        <i className="zmdi zmdi-youtube"></i>
                                    </a>
                                )}
                            </div>
                            <div className="footer-text text-center">
                                <span>Copyright © {new Date().getFullYear()} <a href="https://justify.com.tr">justify crea react project</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tap-top">
                <div>
                    <i className="zmdi zmdi-long-arrow-up"></i>
                </div>
            </div>
        </div>
    );
};

export default Footer;







